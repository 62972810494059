const configs = {
	dev: {
		url: 'https://aidera.bokus.ru',
		webUrl: 'https://web.aidera.bokus.ru',
		chatPort: 6011,
		yandexMetricaApiKey: '346753a5-508d-44b6-8fd0-56962a098449',
		gtmId: 'GTM-P9ZJNBH',
		isDev: true,
		isB2B: false,
	},
	prod: {
		url: 'https://psy.aidera.help',
		webUrl: 'https://web.aidera.help',
		chatPort: 6001,
		yandexMetricaApiKey: 'aa29b93a-aff6-4eeb-99f3-fd6b6ec7f06e',
		gtmId: 'GTM-P9ZJNBH',
		isDev: false,
		isB2B: false,
	},
	b2b_dev: {
		url: 'https://aidera.bokus.ru',
		webUrl: 'https://b2b.aidera.bokus.ru',
		chatPort: null,
		yandexMetricaApiKey: '346753a5-508d-44b6-8fd0-56962a098449',
		gtmId: 'GTM-P9ZJNBH',
		isDev: true,
		isB2B: true,
	},
	b2b_prod: {
		url: 'https://psy.aidera.help',
		webUrl: 'https://b2b.aidera.help',
		chatPort: null,
		yandexMetricaApiKey: 'aa29b93a-aff6-4eeb-99f3-fd6b6ec7f06e',
		gtmId: 'GTM-P9ZJNBH',
		isDev: false,
		isB2B: true,
	},
}

const config = configs.dev

export const androidStores = ['play_market', 'app_gallery'] as const

export default {
	store: androidStores[0],
	smsCodeLength: 4,
	showReduxAction: false,
	api: {
		baseUrl: config.url,
		url: config.url + '/api',
		showLogs: false,
		useFixture: false,
		fixtureDuration: 1000,
		messageDuration: 5000,
		chatPort: config.chatPort,
	},
	oneSignalAppID: '76d30a2d-e0f9-4555-800e-4f146a52f9d8',
	animationDuration: 300,
	chat: {
		maxFileSize: 50,
		maxFiles: undefined,
		fileTypes: ['jpg', 'jpeg'
		, 'png', 'doc', 'docx', 'xls', 'xlsx', 'zip', 'rar', 'pdf', 'dwg', 'bmp'],
	},
	yandexMetricaApiKey: config.yandexMetricaApiKey,
	support_mail: 'support@aidera.online',
	stores: {
		google_play: 'https://play.google.com/store/apps/details?id=ru.bokus.aidera',
		app_store: 'https://apps.apple.com/ru/app/aidera/id1585351659',
		app_gallery: 'https://appgallery.huawei.com/app/C104724531',
	},
	web_url: config.webUrl,
	web_version: '1.8.3',
	web_b2b_version: '1.0.0',
	isDev: config.isDev,
	isB2B: config.isB2B,
	trackerDefaultColors: [
		'#EDF9EE',
		'#F0DEDE',
		'#DEE2F0',
		'#EDF6F9',
		'#E5EDD4',
	],
}
